import { GET_CARS } from '../constants/scannedCars';

const initialState = {
  ownerId: null,
  isWorkshopOwnership: null,
  cars: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CARS:
      return {
        ...state,
        ownerId: action.ownerId,
        isWorkshopOwnership: action.isWorkshopOwnership,
        cars: action.cars,
      };
    default:
      return state;
  }
};
