import {
  APPEND_WORKSHOP_UNSCHEDULED_VISITS,
  GET_WORKSHOP_UNSCHEDULED_VISITS,
} from '../constants/visitsUnscheduledList';

const initialState = {
  ownerId: null,
  visits: [],
  count: null,
  limit: null,
  offset: null,
  next: null,
  previous: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_WORKSHOP_UNSCHEDULED_VISITS:
      return {
        ...state,
        ownerId: action.ownerId,
        visits: action.visits,
        count: action.count,
        limit: action.limit,
        offset: action.offset,
        next: action.next,
        previous: action.previous,
      };
    case APPEND_WORKSHOP_UNSCHEDULED_VISITS:
      return {
        ...state,
        ownerId: action.ownerId,
        visits: [...state.visits, ...action?.visits],
        count: action.count,
        limit: action.limit,
        offset: action.offset,
        next: action.next,
        previous: action.previous,
      };
    default:
      return state;
  }
};
