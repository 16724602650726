import { GET_CLIENT_VISIT_DETAIL, GET_WORKSHOP_VISIT_DETAIL } from '../constants/visit';

const initialState = {
  ownerId: null,
  visitDetail: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENT_VISIT_DETAIL:
      return {
        ...state,
        visitDetail: action.visitDetail,
      };
    case GET_WORKSHOP_VISIT_DETAIL:
      return {
        ...state,
        visitDetail: action.visitDetail,
      };
    default:
      return state;
  }
};
