import { GET_PROFILE } from '../constants/profile';

const initialState = {
  email: null,
  username: null,
  slug: null,
  name: null,
  surname: null,
  bio: null,
  phone: null,
  image: null,
  isOwner: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE:
      return {
        ...state,
        email: action.email,
        username: action.username,
        slug: action.slug,
        name: action.name,
        surname: action.surname,
        bio: action.bio,
        phone: action.phone,
        image: action.image,
        isOwner: action.isOwner,
      };
    default:
      return state;
  }
};
