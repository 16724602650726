import { GET_REGULAR_SERVICES } from '../constants/regularServicesList';

const initialState = {
  workshopId: null,
  COMMON: undefined,
  WORKSHOP: undefined,
  TIRE_SHOP: undefined,
  AIR_CONDITION: undefined,
  DETAILING: undefined,
  CAR_INSPECTION: undefined,
  VEHICLE_CONTROL_STATION: undefined,
  EMERGENCY: undefined,
  LPG: undefined,
  HYBRID: undefined,
  BODYWORK: undefined,
  ELECTRONICS: undefined,
  ELECTRICAL: undefined,
  CAR_GLASS: undefined,
  GEARBOX: undefined,
  UPHOLSTERY: undefined,
  ADAS: undefined,
  REGENERATION: undefined,
  AUDIO: undefined,
  CNG: undefined,
  TUNING: undefined,
  OFF_ROAD: undefined,
  MOTORCYCLE: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REGULAR_SERVICES:
      return {
        ...state,
        workshopId: action.workshopId,
        COMMON: action.regularServices?.COMMON,
        WORKSHOP: action.regularServices?.WORKSHOP,
        TIRE_SHOP: action.regularServices?.TIRE_SHOP,
        AIR_CONDITION: action.regularServices?.AIR_CONDITION,
        DETAILING: action.regularServices?.DETAILING,
        CAR_INSPECTION: action.regularServices?.CAR_INSPECTION,
        VEHICLE_CONTROL_STATION: action.regularServices?.VEHICLE_CONTROL_STATION,
        EMERGENCY: action.regularServices?.EMERGENCY,
        LPG: action.regularServices?.LPG,
        HYBRID: action.regularServices?.HYBRID,
        BODYWORK: action.regularServices?.BODYWORK,
        ELECTRONICS: action.regularServices?.ELECTRONICS,
        ELECTRICAL: action.regularServices?.ELECTRICAL,
        CAR_GLASS: action.regularServices?.CAR_GLASS,
        GEARBOX: action.regularServices?.GEARBOX,
        UPHOLSTERY: action.regularServices?.UPHOLSTERY,
        ADAS: action.regularServices?.ADAS,
        REGENERATION: action.regularServices?.REGENERATION,
        AUDIO: action.regularServices?.AUDIO,
        CNG: action.regularServices?.CNG,
        TUNING: action.regularServices?.TUNING,
        OFF_ROAD: action.regularServices?.OFF_ROAD,
        MOTORCYCLE: action.regularServices?.MOTORCYCLE,
      };
    default:
      return state;
  }
};
