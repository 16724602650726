import {
  GET_ORDER,
  GET_ORDER_PAYMENT_STATUS,
  RESET_ORDER,
  RESET_ORDER_PAYMENT_STATUS,
} from '../constants/order';

const initialState = {
  id: null,
  status: null,
  currency: null,
  total: null,
  fee: null,
  totalWithFee: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDER:
      return {
        ...state,
        id: action.id,
        status: action.status,
        currency: action.currency,
        total: action.total,
        fee: action.fee,
        totalWithFee: action.totalWithFee,
      };
    case GET_ORDER_PAYMENT_STATUS:
      return {
        ...state,
        status: action.status,
      };
    case RESET_ORDER:
      return initialState;
    case RESET_ORDER_PAYMENT_STATUS:
      return {
        ...state,
        status: null,
      };
    default:
      return state;
  }
};
