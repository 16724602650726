import { combineReducers } from 'redux';

import carReducer from '../store/reducers/car';
import employeesListReducer from '../store/reducers/employeesList';
import helpDeskTicketReducer from '../store/reducers/helpdeskTicketsList';
import historyEntriesListReducer from '../store/reducers/historyEntriesList';
import historyEntryReducer from '../store/reducers/historyEntry';
import orderReducer from '../store/reducers/order';
import profileReducer from '../store/reducers/profile';
import regularServicesForTypeReducer from '../store/reducers/regularServicesForType';
import regularServicesListReducer from '../store/reducers/regularServicesList';
import carsListReducer from '../store/reducers/scannedCars';
import serviceCartReducer from '../store/reducers/serviceCart';
import serviceReportReducer from '../store/reducers/serviceReport';
import visitReducer from '../store/reducers/visit';
import visitsCalendarReducer from '../store/reducers/visitsCalendar';
import visitsForDateListReducer from '../store/reducers/visitsForDateList';
import visitsOngoingListReducer from '../store/reducers/visitsOngoingList';
import visitsUnscheduledListReducer from '../store/reducers/visitsUnscheduledList';
import wishListReducer from '../store/reducers/wishList';
import workshopReducer from '../store/reducers/workshop';
import workshopContactReducer from '../store/reducers/workshopContact';
import workshopOpeningHoursReducer from '../store/reducers/workshopOpeningHours';
import workshopReviewsReducer from '../store/reducers/workshopReviewsList';
import workshopTypesReducer from '../store/reducers/workshopTypes';

export default combineReducers({
  visitsCalendar: visitsCalendarReducer,
  visitsForDateList: visitsForDateListReducer,
  visitsOngoingList: visitsOngoingListReducer,
  visitsUnscheduledList: visitsUnscheduledListReducer,
  scannedCars: carsListReducer,
  car: carReducer,
  visit: visitReducer,
  order: orderReducer,
  serviceCart: serviceCartReducer,
  serviceReport: serviceReportReducer,
  wishList: wishListReducer,
  workshop: workshopReducer,
  workshopContact: workshopContactReducer,
  workshopOpeningHours: workshopOpeningHoursReducer,
  workshopTypes: workshopTypesReducer,
  employeesList: employeesListReducer,
  workshopReviewsList: workshopReviewsReducer,
  historyEntriesList: historyEntriesListReducer,
  historyEntry: historyEntryReducer,
  regularServicesList: regularServicesListReducer,
  regularServicesForType: regularServicesForTypeReducer,
  helpdeskTickets: helpDeskTicketReducer,
  profile: profileReducer,
});
