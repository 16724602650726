import { GET_WORKSHOP_CONTACT, UPDATE_WORKSHOP_CONTACT } from '../constants/workshopContact';

const initialState = {
  workshopOwnershipId: null,
  contactPhone: null,
  contactPhone2: null,
  contactEmail: null,
  contactEmail2: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_WORKSHOP_CONTACT:
      return {
        ...state,
        workshopOwnershipId: action.workshopOwnershipId,
        contactPhone: action.contactPhone,
        contactPhone2: action.contactPhone2,
        contactEmail: action.contactEmail,
        contactEmail2: action.contactEmail2,
      };
    case UPDATE_WORKSHOP_CONTACT:
      return {
        ...state,
        contactPhone: action.contactPhone,
        contactPhone2: action.contactPhone2,
        contactEmail: action.contactEmail,
        contactEmail2: action.contactEmail2,
      };
    default:
      return state;
  }
};
