import { GET_WORKSHOP, RESET_WORKSHOP } from '../constants/workshop';

const initialState = {
  workshopId: null,
  isActive: null,
  isOwner: null,
  name: null,
  city: null,
  street: null,
  streetNumber: null,
  postcode: null,
  latitude: null,
  longitude: null,
  slug: null,
  description: null,
  numberOfReviews: null,
  averageRate: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_WORKSHOP:
      return {
        ...state,
        workshopId: action.id,
        isActive: action.active,
        isOwner: action.isOwner,
        name: action.name,
        city: action.city,
        street: action.street,
        streetNumber: action.streetNumber,
        postcode: action.postcode,
        latitude: action.latitude,
        longitude: action.longitude,
        slug: action.slug,
        description: action.description,
        image: action.image,
        numberOfReviews: action.numberOfReviews,
        averageRate: action.averageRate,
      };
    case RESET_WORKSHOP:
      return initialState;
    default:
      return state;
  }
};
