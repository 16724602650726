import { GET_EMPLOYEES_LIST, RESET_EMPLOYEES_LIST } from '../constants/employeesList';

const initialState = {
  employees: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EMPLOYEES_LIST:
      return {
        ...state,
        employees: action.payload,
      };
    case RESET_EMPLOYEES_LIST:
      return initialState;
    default:
      return state;
  }
};
