import { GET_WISH_LIST, RESET_WISH_LIST } from '../constants/wishList';

const initialState = {
  id: null,
  items: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_WISH_LIST:
      return {
        ...state,
        id: action.id,
        items: action.items,
      };
    case RESET_WISH_LIST:
      return initialState;
    default:
      return state;
  }
};
