import { GET_WORKSHOP_TYPES } from '../constants/workshopTypes';

const initialState = {
  workshopId: null,
  WORKSHOP: false,
  TIRE_SHOP: false,
  AIR_CONDITION: false,
  DETAILING: false,
  CAR_INSPECTION: false,
  VEHICLE_CONTROL_STATION: false,
  EMERGENCY: false,
  LPG: false,
  HYBRID: false,
  BODYWORK: false,
  ELECTRONICS: false,
  ELECTRICAL: false,
  CAR_GLASS: false,
  GEARBOX: false,
  UPHOLSTERY: false,
  ADAS: false,
  REGENERATION: false,
  AUDIO: false,
  CNG: false,
  TUNING: false,
  OFF_ROAD: false,
  MOTORCYCLE: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_WORKSHOP_TYPES:
      return {
        ...state,
        workshopId: action.workshopId,
        WORKSHOP: action.types?.WORKSHOP ?? false,
        TIRE_SHOP: action.types?.TIRE_SHOP ?? false,
        AIR_CONDITION: action.types?.AIR_CONDITION ?? false,
        DETAILING: action.types?.DETAILING ?? false,
        CAR_INSPECTION: action.types?.CAR_INSPECTION ?? false,
        VEHICLE_CONTROL_STATION: action.types?.VEHICLE_CONTROL_STATION ?? false,
        EMERGENCY: action.types?.EMERGENCY ?? false,
        LPG: action.types?.LPG ?? false,
        HYBRID: action.types?.HYBRID ?? false,
        BODYWORK: action.types?.BODYWORK ?? false,
        ELECTRONICS: action.types?.ELECTRONICS ?? false,
        ELECTRICAL: action.types?.ELECTRICAL ?? false,
        CAR_GLASS: action.types?.CAR_GLASS ?? false,
        GEARBOX: action.types?.GEARBOX ?? false,
        UPHOLSTERY: action.types?.UPHOLSTERY ?? false,
        ADAS: action.types?.ADAS ?? false,
        REGENERATION: action.types?.REGENERATION ?? false,
        AUDIO: action.types?.AUDIO ?? false,
        CNG: action.types?.CNG ?? false,
        TUNING: action.types?.TUNING ?? false,
        OFF_ROAD: action.types?.OFF_ROAD ?? false,
        MOTORCYCLE: action.types?.MOTORCYCLE ?? false,
      };
    default:
      return state;
  }
};
