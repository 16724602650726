import { GET_WORKSHOP_OPENING_HOURS } from '../constants/workshopOpeningHours';

const initialState = {
  workshopOwnershipId: null,
  monday: null,
  tuesday: null,
  wednesday: null,
  thursday: null,
  friday: null,
  saturday: null,
  sunday: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_WORKSHOP_OPENING_HOURS:
      return {
        ...state,
        workshopOwnershipId: action.workshopOwnershipId,
        monday: action.monday,
        tuesday: action.tuesday,
        wednesday: action.wednesday,
        thursday: action.thursday,
        friday: action.friday,
        saturday: action.saturday,
        sunday: action.sunday,
      };
    default:
      return state;
  }
};
