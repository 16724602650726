import { APPEND_HISTORY_ENTRIES, GET_HISTORY_ENTRIES } from '../constants/historyEntriesList';

const initialState = {
  historyEntries: [],
  count: null,
  limit: null,
  offset: null,
  next: null,
  previous: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_HISTORY_ENTRIES:
      return {
        ...state,
        historyEntries: action.historyEntries,
        count: action.count,
        limit: action.limit,
        offset: action.offset,
        next: action.next,
        previous: action.previous,
      };
    case APPEND_HISTORY_ENTRIES:
      return {
        ...state,
        historyEntries: [...state.historyEntries, ...action?.historyEntries],
        count: action.count,
        limit: action.limit,
        offset: action.offset,
        next: action.next,
        previous: action.previous,
      };
    default:
      return state;
  }
};
