import { GET_SERVICE_REPORT, RESET_SERVICE_REPORT } from '../constants/serviceReport';

const initialState = {
  id: null,
  oil: null,
  brake: null,
  coolant: null,
  tires: null,
  lights: null,
  mileage: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SERVICE_REPORT:
      return {
        ...state,
        id: action.id,
        oil: action.oil,
        brake: action.brake,
        coolant: action.coolant,
        tires: action.tires,
        lights: action.lights,
        mileage: action.mileage,
      };
    case RESET_SERVICE_REPORT:
      return initialState;
    default:
      return state;
  }
};
