import { GET_CAR, RESET_CAR } from '../constants/car';

const initialState = {
  ownerId: null,
  carDetail: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CAR:
      return {
        ...state,
        ownerId: action.ownerId,
        carDetail: action.carDetail,
      };
    case RESET_CAR:
      return initialState;
    default:
      return state;
  }
};
