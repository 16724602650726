/* eslint-disable react/jsx-props-no-spreading */
import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/list/main.css';
import '@fullcalendar/timegrid/main.css';

import { SessionProvider } from 'next-auth/react';
import { Provider } from 'react-redux';

import { useStore } from '../store';

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  const store = useStore(pageProps.initialReduxState);

  return (
    <Provider store={store}>
      <SessionProvider session={session} refetchInterval={60 * 15}>
        <Component {...pageProps} />
      </SessionProvider>
    </Provider>
  );
}

export default MyApp;
