import { GET_REGULAR_SERVICES_FOR_TYPE } from '../constants/regularServicesForType';

const initialState = {
  regularServicesForType: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REGULAR_SERVICES_FOR_TYPE:
      return {
        ...state,
        regularServicesForType: action.regularServicesForType,
      };
    default:
      return state;
  }
};
