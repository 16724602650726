import { GET_HISTORY_ENTRY } from '../constants/historyEntry';

const initialState = {
  id: null,
  visitDetail: null,
  mileage: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_HISTORY_ENTRY:
      return {
        ...state,
        id: action.id,
        visitDetail: action.visitDetail,
        mileage: action.mileage,
      };
    default:
      return state;
  }
};
