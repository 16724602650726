import { GET_SERVICE_CART, RESET_SERVICE_CART } from '../constants/serviceCart';

const initialState = {
  id: null,
  items: [],
  price: null,
  fee: null,
  priceWithFee: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SERVICE_CART:
      return {
        ...state,
        id: action.id,
        items: action.items,
        price: action.price,
        fee: action.fee,
        priceWithFee: action.priceWithFee,
      };
    case RESET_SERVICE_CART:
      return initialState;
    default:
      return state;
  }
};
